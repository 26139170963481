const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length === 0) {
      return `${onlyNums} `;
    }
    if (onlyNums.length === 5) {
      return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2)}`;
    }
  }
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2)}`;
  }

  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2)}`;
  }
  return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 5)} ${onlyNums.slice(
    5,
    7
  )} ${onlyNums.slice(7, 9)}`;
};

export const maskPhone = (phone) => {
  if (!phone) return "";
  return `${phone.substring(0, 6)}*****${phone.substring(phone.length - 2)}`;
};

const PHONE_REGEX = /(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/;

export const formatPhone = (value = "") => {
  const digits = value.replace(/[^\d]/g, "");

  const [, , areaCode, ...numberSegments] = PHONE_REGEX.exec(digits) || [];

  const code = ["+38", areaCode].filter(Boolean).join(" (");
  const number = numberSegments.filter(Boolean).join("-");
  return [code, number].filter(Boolean).join(") ");
};

export default normalizePhone;
