import React, { Component } from "react";
import jwtDecode from "jwt-decode";

import { Async, Connect, Form } from "@edenlabllc/ehealth-components";

import { login, setData } from "../../../redux/session";
import { registerConfidant } from "../../../redux/pis";
import { fetchDictionaries } from "../../../redux/dictionaries";
import { getToken } from "../../../reducers";
import handleSignUpValidationError from "../../../helpers/pisErrors";
import { userConfidantValidation } from "./redux";

const SignUpPisConfidant = (props) => <FormConnector {...props} />;

const FormConnector = (props) => (
  <Connect
    mapDispatchToProps={{
      fetchDictionaries,
      registerUserConfidant,
      userConfidantValidation,
      setData
    }}
    mapStateToProps={(state) => ({
      token: getToken(state)
    })}
  >
    {({ fetchDictionaries, ...actions }) => (
      <Async
        await={() =>
          fetchDictionaries({
            name: "GENDER,PHONE_TYPE,AUTHENTICATION_METHOD,DOCUMENT_TYPE,PHONE_TYPE,SETTLEMENT_TYPE,STREET_TYPE,ADDRESS_TYPE,PREFERRED_WAY_COMMUNICATION,DOCUMENT_RELATIONSHIP_TYPE,COUNTRY"
          })
        }
      >
        <SignUpPisConfidantForm {...actions} {...props} />
      </Async>
    )}
  </Connect>
);

export default SignUpPisConfidant;

class SignUpPisConfidantForm extends Component {
  state = {
    person: undefined,
    errors: undefined,
    jwt: ""
  };

  componentDidMount() {
    const { userConfidantValidation, location, setData } = this.props;

    const errors = this.validateQueryParams();
    if (errors.length) {
      return this.setState({ errors });
    }

    const fetchData = async () => {
      await setData({ token: location.query.token });
      const response = await userConfidantValidation({
        signed_content: location.query.user_data.replace(/ /g, "+"),
        signed_content_encoding: "base64"
      });

      if (response.error) {
        return this.handleFailure(response.error);
      }

      this.setState({
        person: response.person,
        jwt: response.token
      });
    };
    fetchData();
  }

  validateQueryParams() {
    const { location } = this.props;
    const { client_id, redirect_uri, user_data, token } = location.query;
    const errors = [];
    if (!client_id) {
      errors.push("Не вказаний ідентифікатор додатку для авторизації");
    }
    if (!redirect_uri) {
      errors.push("Не вказано адресу зворотнього визову");
    }
    if (!user_data) {
      errors.push("Не вказано дані для авторизації");
    }
    if (!token) {
      errors.push("Не вказано токен особи для авторизації");
    }

    return errors;
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        {React.cloneElement(this.props.children, {
          person: this.state.person,
          errors: this.state.errors
        })}
      </Form>
    );
  }

  tokenData = (token) => {
    try {
      return jwtDecode(token);
    } catch (e) {
      return {};
    }
  };

  handleSubmit = async () => {
    const { registerUserConfidant, location, router } = this.props;

    const { user_data } = location.query;

    try {
      await registerUserConfidant({
        signed_content: user_data.replace(/ /g, "+"),
        signed_content_encoding: "base64",
        jwt: this.state.jwt
      });

      router.push({ ...location, pathname: "/accept" });
    } catch (error) {
      return this.handleFailure(error);
    }
  };

  handleFailure = (error) => {
    const errors = handleSignUpValidationError(error);
    this.setState({ errors });
  };
}

const registerUserConfidant = (payload) => async (dispatch) => {
  const {
    error,
    payload: { response, data }
  } = await dispatch(registerConfidant(payload));

  if (error) throw response.error;

  return dispatch(login(data.access_token));
};
