import { push } from "react-router-redux";
import { createConfidantSessionToken } from "../../../redux/pis";
import { login } from "../../../redux/session";
import {
  signInMessages,
  signInConfidantMessages
} from "../../../helpers/pisErrors";
import { getLocation } from "../../../reducers";
import env from "../../../env";

export const onSubmit =
  ({ user_data }) =>
  (dispatch, getState) =>
    dispatch(
      createConfidantSessionToken({
        grant_type: "pis_auth",
        signed_content: user_data,
        signed_content_encoding: "base64",
        client_id: env.REACT_APP_CLIENT_ID,
        scope: "app:authorize"
      })
    ).then((action) => {
      if (action.error) {
        const { message, invalid } = action.payload.response.error;

        const errors = [];
        if (invalid && invalid.length) {
          invalid.forEach((i) => {
            i.rules &&
              i.rules.forEach((rule) => {
                errors.push(
                  signInMessages[rule.description] ||
                    signInConfidantMessages[rule.description] ||
                    rule.description
                );
              });
          });
        } else {
          errors.push(
            signInMessages[message] ||
              signInConfidantMessages[message] ||
              message
          );
        }
        return { errors };
      }

      dispatch(login(action.payload.access_token, action.payload.expires_at));

      const state = getState();
      const location = getLocation(state);

      dispatch(push({ ...location, pathname: "/accept" }));
      return true;
    });
