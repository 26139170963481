import _slicedToArray from "/app/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var parseDate = function parseDate(text) {
  var digits = text.replace(/\D/g, "");

  // [_matches, year, month, day]
  var _exec = /^(\d{0,2})(\d{0,2})(\d{0,4})/.exec(digits),
    _exec2 = _slicedToArray(_exec, 4),
    day = _exec2[1],
    month = _exec2[2],
    year = _exec2[3];
  return [year, month, day].filter(Boolean).join("-");
};
export default parseDate;