import React, { Component } from "react";
import jwtDecode from "jwt-decode";

import {
  Async,
  Connect,
  StepForm,
  SUBMIT_ERROR
} from "@edenlabllc/ehealth-components";

import { sendOtp } from "../../../redux/cabinet";
import { login, setData } from "../../../redux/session";
import { register } from "../../../redux/pis";
import { fetchDictionaries } from "../../../redux/dictionaries";
import { getToken } from "../../../reducers";
import handleSignUpValidationError from "../../../helpers/pisErrors";
import { userValidation } from "./redux";

const SignUpPisNextPage = (props) => <FormConnector {...props} />;

const FormConnector = (props) => (
  <Connect
    mapDispatchToProps={{
      fetchDictionaries,
      sendOtp,
      registerUser,
      userValidation,
      setData
    }}
    mapStateToProps={(state) => ({
      token: getToken(state)
    })}
  >
    {({ fetchDictionaries, ...actions }) => (
      <Async
        await={() =>
          fetchDictionaries({
            name: "GENDER,PHONE_TYPE,AUTHENTICATION_METHOD,DOCUMENT_TYPE,PHONE_TYPE,SETTLEMENT_TYPE,STREET_TYPE,ADDRESS_TYPE,PREFERRED_WAY_COMMUNICATION,COUNTRY"
          })
        }
      >
        <SignUpPisNextForm {...actions} {...props} />
      </Async>
    )}
  </Connect>
);

export default SignUpPisNextPage;

class SignUpPisNextForm extends Component {
  state = {
    person: undefined,
    errors: undefined
  };

  componentDidMount() {
    const { userValidation, location, setData } = this.props;
    const fetchData = async () => {
      const response = await userValidation({
        signed_content: location.query.user_data.replace(/ /g, "+"),
        signed_content_encoding: "base64"
      });

      if (response.error) {
        return this.handleFailure(response.error);
      }
      await setData({ token: response.token });

      this.setState({
        person: response.person
      });
    };
    fetchData();
  }

  render() {
    return (
      <StepForm
        initialValues={this.state.person}
        step={this.currentStep}
        transitions={{
          person: this.personTransition
        }}
        onSubmit={this.handleSubmit}
      >
        {React.cloneElement(this.props.children, {
          person: this.state.person,
          errors: this.state.errors
        })}
      </StepForm>
    );
  }

  get currentStep() {
    return this.props.location.pathname.replace("/sign-up/pis/", "");
  }

  tokenData = (token) => {
    try {
      return jwtDecode(token);
    } catch (e) {
      return {};
    }
  };

  personTransition = async (person) => {
    const { sendOtp, router, location, token } = this.props;

    try {
      const { error, payload } = await sendOtp({
        factor: person.authentication_methods[0].phone_number,
        type: "SMS",
        content_hash: this.tokenData(token).content_hash
      });

      if (error) throw payload.response.error;
      if (payload.data && payload.data.result === "Verified") {
        await this.handleSubmit({});
      } else {
        router.push({ ...location, pathname: "/sign-up/pis/otp" });
      }
    } catch (error) {
      return this.handleFailure(error);
    }
  };

  handleSubmit = async ({ otp }) => {
    const { registerUser, location, router } = this.props;

    const { user_data } = location.query;

    try {
      await registerUser({
        signed_content: user_data.replace(/ /g, "+"),
        signed_content_encoding: "base64",
        ...(otp && { otp: Number(otp) })
      });

      router.push({ ...location, pathname: "/accept" });
    } catch (error) {
      return this.handleFailure(error);
    }
  };

  handleFailure = (error) => {
    const { router, location } = this.props;
    if (error.message === "Invalid verification code.") {
      const invalid = [
        {
          entry: "$.otp",
          rules: [
            {
              rule: "invalid"
            }
          ]
        }
      ];
      return { [SUBMIT_ERROR]: invalid };
    }

    const errors = handleSignUpValidationError(error);

    this.setState({ errors });
    if (location.pathname.includes("/otp")) {
      router.push({ ...location, pathname: "/sign-up/pis/person" });
    }
  };
}

const registerUser = (payload) => async (dispatch) => {
  const {
    error,
    payload: { response, data }
  } = await dispatch(register(payload));

  if (error) throw response.error;

  return dispatch(login(data.access_token));
};
