import _slicedToArray from "/app/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var formatDate = function formatDate(value) {
  if (typeof value !== "string") return value;
  var digits = value.replace(/\D/g, "").slice(0, 8);

  // [_matches, year, month, day]
  var _exec = /(\d{0,4}?)(\d{0,2}?)(\d{0,2}?)$/.exec(digits),
    _exec2 = _slicedToArray(_exec, 4),
    year = _exec2[1],
    month = _exec2[2],
    day = _exec2[3];
  return [day, month, year].filter(Boolean).join(".");
};
export default formatDate;