import React from "react";

import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";

import env from "../../../env";

import {
  Main,
  Header,
  Article,
  NarrowContainer
} from "../../../components/CenterLayout";
import { H1 } from "../../../components/Title";
import DigitalSignatureForm from "../../forms/DigitalSignatureForm";

const SignPage = () => (
  <Main>
    <Header>
      <H1>Електронний цифровий підпис</H1>
    </Header>
    <Article>
      <NarrowContainer>
        <Signer.Child
          allowedOrigins={env.REACT_APP_ALLOWED_SIGN_ORIGINS.split(",")}
        >
          {({ data, onSignSuccess, onSignError }) =>
            data && (
              <DigitalSignatureForm
                onSubmit={async (ds) => {
                  try {
                    const content = JSON.stringify(data);

                    const signedContent =
                      env.REACT_APP_DIGITAL_SIGNATURE_ENABLED
                        ? await ds.signData(content)
                        : btoa(unescape(encodeURIComponent(content)));

                    let meta;

                    if (!env.REACT_APP_DIGITAL_SIGNATURE_ENABLED) {
                      meta = {
                        drfo: ds.privateKeyOwner.subjDRFOCode,
                        given_name: encodeURIComponent(
                          ds.privateKeyOwner.givenName
                        ),
                        surname: encodeURIComponent(ds.privateKeyOwner.surname)
                      };
                    }

                    onSignSuccess({ signedContent, meta });
                  } catch (error) {
                    onSignError(error);
                  }
                }}
              />
            )
          }
        </Signer.Child>
      </NarrowContainer>
    </Article>
  </Main>
);

export default SignPage;
